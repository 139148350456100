<template>
  <v-container fluid grid-list-xs class="bgLevels">
    <v-row justify="center" class="text-center mb-16 mt-5 mx-0">
      <v-col cols="12" sm="5" id="school">
        <div class="subSection">
          <v-chip label class="blue"
            ><p class="mb-0 white--text">Nivele oferite</p></v-chip
          >
        </div>
        <h1 class="mb-3 black--text">Programe educationale</h1>
        <p>
          Variante program: A. 08:00-12:00 | B. 08:00-16:30 | C. 08:00-19:00
        </p>
        <v-btn color="red" class="boxRed mr-2 my-2" dark @click="dialog = true">
          Programul zilnic
        </v-btn>
        <v-dialog v-model="dialog" width="50%" scrollable>
          <v-card>
            <v-toolbar dark color="blue">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Program Zilnic</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-img
              lazy-src="../assets/preSchedule.jpeg"
              src="../assets/preSchedule.jpeg"
            ></v-img>
            <v-divider></v-divider>
          </v-card>
        </v-dialog>
        <v-btn
          color="orange"
          class="boxOrange my-2"
          dark
          @click="dialog1 = true"
        >
          Curriculum
        </v-btn>
        <v-dialog v-model="dialog1" width="80%" scrollable>
          <v-card>
            <v-toolbar dark color="blue">
              <v-btn icon dark @click="dialog1 = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Curriculum</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-img
              lazy-src="../assets/preSchool.jpeg"
              src="../assets/preSchool.jpeg"
            ></v-img>
            <v-divider></v-divider>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-container grid-list-xs>
      <v-row justify="space-around" class="mx-0">
        <v-col cols="11" md="3" class="pa-0 pb-3">
          <v-img
            src="../assets/levels/topLeft.jpeg"
            aspect-ratio="1.3"
            height="270px"
          ></v-img>
        </v-col>
        <v-col cols="11" md="3" class="text-center setHeight">
          <h3 class="mt-10">Clasa pregatitoare</h3>
          <p>Varsta: 6 ani</p>
          <v-btn
            color="blue"
            class="boxBlue disable-events white--text btnRound"
            >3 locuri libere</v-btn
          >
        </v-col>
        <v-col cols="11" md="3" class="pa-0 pb-3">
          <v-img
            src="../assets/levels/topRight.jpeg"
            aspect-ratio="1.3"
            height="270px"
          ></v-img>
        </v-col>
        <v-col cols="11" md="3" class="text-center setHeight">
          <h3 class="mt-10">Afterschool</h3>
          <p>Varsta: 6-11 ani</p>
          <v-btn
            color="blue"
            class="boxBlue disable-events white--text btnRound"
            >3 locuri libere</v-btn
          >
        </v-col>
      </v-row>
      <v-row justify="space-around" class="mt-0 mb-5 mx-0">
        <v-col cols="11" md="3" class="text-center setHeight">
          <h3 class="mt-10">Gradinita</h3>
          <p>Varsta: 2-5 ani</p>
          <v-btn
            color="blue"
            class="boxBlue disable-events white--text btnRound"
            >2 locuri libere</v-btn
          >
        </v-col>
        <v-col
          cols="11"
          md="3"
          class="pa-0 pb-3"
          :order="$vuetify.breakpoint.xs ? 'first' : 'second'"
        >
          <v-img
            src="../assets/levels/bottomLeft.jpeg"
            aspect-ratio="1.3"
            height="270px"
          ></v-img>
        </v-col>
        <v-col
          cols="11"
          md="3"
          class="text-center setHeight"
          :order="$vuetify.breakpoint.xs ? 'last' : 'third'"
        >
          <h3 class="mt-10">Scoala primara</h3>
          <p>Varsta: 7-11 ani</p>
          <v-btn
            color="blue"
            class="boxBlue disable-events white--text btnRound"
            >2 locuri libere</v-btn
          >
        </v-col>
        <v-col cols="11" md="3" class="pa-0 pb-3">
          <v-img
            src="../assets/levels/bottomRight.jpeg"
            aspect-ratio="1.3"
            height="270px"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "LevelsComponent",

  data() {
    return {
      tab: 0,
      state: "",
      show: false,
      selectedItem: {},
      dialog: false,
      dialog1: false,

      tabs: ["Prescolar", "Scoala Primara"],

      preList: [
        {
          image: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
          image1: "preSchool",
          title: "Structura anului scolar",
          btn: "structura anului",
          descriptions: [
            "Gradinita Anastasya isi desfasoara continuu activitatea pe parcursul anului 2015-2016, inclusiv in perioada vacantelor scolare ( de iarna / de primavara / de vara /  intersemestriala ).",
            "In perioada vacantelor, cu exceptia celei de vara, se organizeaza activitati de recuperare / extindere / consolidare / activitati extracurriculare / alte cluburi.",
            "In perioada vacantei de vara, se deruleaza programul GRADINITEI DE VARA .",
          ],
          secondTitle: "Zilele in care institutia este inchisa sunt :",
          description:
            "25-26 decembrie  2019  /  1-2 ianuarie  /  24 ianuarie  /  17 aprilie  /  20 aprilie  /  1 mai  /  1 iunie  /  8 iunie  2020 ",
        },
        {
          image: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
          image1: "preSchedule",
          descriptions: [
            "VARIANTA A :  8.00-12.30",
            "VARIANTA B:   8.00-13.30",
            "VARIANTA C :  8.00-19.00",
          ],
          title: "Program scolar zilnic",
          btn: "programul scolar",
        },
        {
          image: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
          image1: "",
          descriptions: [
            "SCOALA PRIMARA ANASTASYA  respecta Curriculum-ul National, promovand, totodata, principii si metode ale invatamantului britanic",
            "Programa romaneasca ne arata ,, ce se invata ”, dar un aspect important este ,, cum se invata”, deoarece fiecare copil traieste in felul lui unic actul invatarii , asa incat o metoda considerata buna pentru unii, este nepotrivita pentru altii. De aceea, abordam,in cadrul procesului de predare –invatare, metode de o mare diversitate si flexibilitate: metodologia centrata pe elev, pe actiunea si angajarea acestuia , care promoveaza metodele activ- participative / metode practice / interdisciplinaritatea si pluridisciplinaritatea",
            "Studierea limbii engleze ( 10 ore /saptamana) Modul cum reusim sa cultivam pasiunile prin intermediul activitatilor extrascolare, valorificand cele sapte tipuri de inteligente ( lingvistica, logico -matematica,spatiala, muzicala, kinetica, interpersonala si intrapersonala ) , reprezinta o conditie importanta pentru angajarea profunda in invatarea scolara",
          ],
          title: "Curriculum",
          secondTitle: "Principii",
          description:
            "Parintii  si profesorii formeaza parteneriate de lucru   .Si unii si altii vor sti care sunt cuvintele care incita la confruntare si cele care invita la cooperare , cele care il pun pe copil in imposibilitatea de a gandi sau cele care descatuseaza dorinta fireasca de a invata. Metodele  moderne de predare vor incuraja copilul sa-si asume responsabilitati , sa creada in ceea ce este si in ceea ce poate devein. Practicam un mod de a comunica prin care eliminam teama si cultivam increderea. Elevii sunt angajati activ in procesul de invatare. Invatam copilul sa priveasca esecul ca pe o posibilitate de a invata ; atunci cand obtine rezultate bune ,gasim impreuna cu copilul calitatile care l-au ajutat ( accent pe calitatile de care dispune )",
          btn: "curriculum",
        },
        // {
        //   image: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
        //   image1: "",
        //   title: "Tabere si excursii",
        //   btn: "tabere si excursii",
        // },
      ],
      primaryList: [
        {
          image: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
          image1: "preSchool",
          title: "Structura anului scolar",
          btn: "structura anului",
          descriptions: [
            "Gradinita Anastasya isi desfasoara continuu activitatea pe parcursul anului 2015-2016, inclusiv in perioada vacantelor scolare ( de iarna / de primavara / de vara /  intersemestriala ).",
            "In perioada vacantelor, cu exceptia celei de vara, se organizeaza activitati de recuperare / extindere / consolidare / activitati extracurriculare / alte cluburi.",
            "In perioada vacantei de vara, se deruleaza programul GRADINITEI DE VARA .",
          ],
          secondTitle: "Zilele in care institutia este inchisa sunt :",
          description:
            "25-26 decembrie  2019  /  1-2 ianuarie  /  24 ianuarie  /  17 aprilie  /  20 aprilie  /  1 mai  /  1 iunie  /  8 iunie  2020 ",
        },
        {
          image: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
          image1: "preSchedule",
          descriptions: [
            "VARIANTA A :  8.00-12.30",
            "VARIANTA B:   8.00-13.30",
            "VARIANTA C :  8.00-19.00",
            "Programul  standard cuprinde :",
            "activitati  curriculare conform  programei  romanesti",
            "program afterschool",
            "10 ore de limba engleza / saptamana   ( ore incluse in taxa )",
            "2  mese principale   +  2 gustari   ( in functie de programul ales )",
            "cluburi",
          ],
          secondTitle: "Oferta nu include cursurile optionale.",
          title: "Program scolar zilnic",
          btn: "programul scolar",
        },
        {
          image: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
          image1: "",
          descriptions: [
            "SCOALA PRIMARA ANASTASYA  respecta Curriculum-ul National, promovand, totodata, principii si metode ale invatamantului britanic",
            "Programa romaneasca ne arata ,, ce se invata ”, dar un aspect important este ,, cum se invata”, deoarece fiecare copil traieste in felul lui unic actul invatarii , asa incat o metoda considerata buna pentru unii, este nepotrivita pentru altii. De aceea, abordam,in cadrul procesului de predare –invatare, metode de o mare diversitate si flexibilitate: metodologia centrata pe elev, pe actiunea si angajarea acestuia , care promoveaza metodele activ- participative / metode practice / interdisciplinaritatea si pluridisciplinaritatea",
            "Studierea limbii engleze ( 10 ore /saptamana) Modul cum reusim sa cultivam pasiunile prin intermediul activitatilor extrascolare, valorificand cele sapte tipuri de inteligente ( lingvistica, logico -matematica,spatiala, muzicala, kinetica, interpersonala si intrapersonala ) , reprezinta o conditie importanta pentru angajarea profunda in invatarea scolara",
          ],
          title: "Curriculum",
          secondTitle: "Principii",
          description:
            "Parintii  si profesorii formeaza parteneriate de lucru. Si unii si altii vor sti care sunt cuvintele care incita la confruntare si cele care invita la cooperare , cele care il pun pe copil in imposibilitatea de a gandi sau cele care descatuseaza dorinta fireasca de a invata. Metodele  moderne de predare vor incuraja copilul sa-si asume responsabilitati , sa creada in ceea ce este si in ceea ce poate devein. Practicam un mod de a comunica prin care eliminam teama si cultivam increderea. Elevii sunt angajati activ in procesul de invatare. Invatam copilul sa priveasca esecul ca pe o posibilitate de a invata ; atunci cand obtine rezultate bune ,gasim impreuna cu copilul calitatile care l-au ajutat ( accent pe calitatile de care dispune )",
          btn: "curriculum",
        },
        // {
        //   image: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
        //   image1: "",
        //   title: "Tabere si excursii",
        //   btn: "tabere si excursii",
        // },
      ],
    };
  },
};
</script>
<style scoped>
.setImage {
  position: absolute;
  height: 90vh;
}
.borderImg {
  border-top: 20px solid white;
  border-left: 20px solid white;
  border-bottom: 20px solid white;
}
.bgLevels {
  background-image: url("../assets/slides/Slide9.jpg");
  width: 100%;
  min-height: 110vh;
  background-size: cover;
}
.btnWidth {
  margin: 0 auto;
  width: 250px !important;
}
.setHeight {
  height: 250px;
}
</style>
