import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        purple: "#6d1bff",
        lightPurple: "#849cfc",
        yellow: "#ffd962",
        red: "#f95f44",
        grey: "#F1F1F1",
        green: "#a6ec8a",
        cyan: "#7bd7f2",
      },
    },
  },
});
export default vuetify;
