import Vue from "vue";
import Vuex from "vuex";
import { app } from "@/firebase";

Vue.use(Vuex);

//const debug = process.env.NODE_ENV !== 'production'
export default new Vuex.Store({
  modules: {},
  state: {
    albumsList: [],
    albumYears: [],
    mainAlbums: [],
    promotionPhotos: null,
    userLoaded: false,
  },
  mutations: {
    SET_PROFILE_INFO(state, payload) {
      state.promotionPhotos = payload.data().promotionPhotos;
      state.promotionPhotos;
    },
    SET_ALBUMS(state, payload) {
      state.albumsList.push(payload);
      state.albumYears.push(payload.year);
      if (state.albumsList[0].albums) {
        state.mainAlbums = state.albumsList[0].albums;
      }
    },
    SET_MAIN_ALBUMS(state, payload) {
      state.mainAlbums = payload;
    },

    PROFILE_LOADED(state) {
      state.userLoaded = true;
    },
  },
  actions: {
    async GET_CURRENT_PROFILE({ commit }) {
      const dataBase = await app
        .collection("accounts")
        .doc("8tmAlRSRQpNqLc4bow6xSLU1kHC3");
      const dbResults = await dataBase.get();
      commit("SET_PROFILE_INFO", dbResults);
      commit("PROFILE_LOADED");
    },
    async GET_ALBUMS({ commit, state }) {
      const dataBase = await app
        .collection("albums")
        .where("userId", "==", "8tmAlRSRQpNqLc4bow6xSLU1kHC3")
        .orderBy("year", "desc");
      const dbResults = await dataBase.get();
      const albums = dbResults;
      albums.forEach((doc) => {
        if (
          !state.albumsList.some((album) => album.docId === doc.data().docId) &&
          doc.data().docId
        ) {
          const data = {
            albums: doc.data().albums,
            year: doc.data().year,
            url: doc.data().url,
            docId: doc.data().docId,
          };
          commit("SET_ALBUMS", data);
        }
      });
    },
  },
  getters: {},
});
