<template>
  <v-container fluid grid-list-xs class="bgInvestments sectDist">
    <v-row justify="center" class="text-center mr-0 sectDist mx-0 px-0">
      <v-card class="roundCard pa-2 sect hidden-xs-only" max-width="700">
        <v-img src="../assets/building.jpeg" height="600px"></v-img>
      </v-card>
      <v-card class="hidden-sm-and-up mx-auto" width="400px">
        <v-img src="../assets/building.jpeg" height="300px"></v-img>
      </v-card>
      <v-col cols="12" offset-sm="1" sm="4" class="mt-10">
        <div class="subSection">
          <v-chip label class="red"
            ><p class="mb-0 white--text">O noua cladire in curand</p></v-chip
          >
        </div>
        <h1 class="mb-15">
          Construim <br />
          pentru viitor
        </h1>
        <v-row justify="center">
          <v-col
            cols="11"
            md="6"
            :class="item.classOuter"
            v-for="(item, i) of items"
            :key="i"
            class="pa-0"
          >
            <v-card
              :color="item.color"
              class="mr-0"
              elevation="0"
              height="120px"
            >
              <v-container grid-list-xs fill-height>
                <v-row align-center justify="center">
                  <h2 class="white--text" style="line-height: 1.1em">
                    {{ item.title }}
                  </h2>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "InvestmentsComponent",

  data: () => ({
    items: [
      {
        color: "red",
        classOuter: "boxRedOuter",
        title: "950mp",
      },
      {
        color: "blue",
        classOuter: "boxBlueOuter",
        title: "I-VIII",
      },
      {
        color: "lightPurple",
        classOuter: " boxLightPurpleOuter",
        title: "Sala de sport",
      },
      {
        color: " orange",
        classOuter: "boxOrangeOuter",
        title: "Capacitate  128 locuri",
      },
    ],
  }),
};
</script>
<style scoped>
.cardOpacity {
  opacity: 0.6;
}

.bgInvestments {
  background-image: url("../assets/slides/Slide4.jpg");
  width: 100%;
  min-height: 100vh;
  background-size: cover;
}
.roundCard {
  border-radius: 200px;
}
</style>
