<template>
  <v-card flat tile width="100%" class="lightPurple text-center">
    <v-container fluid grid-list-xs>
      <v-row
        :justify="$vuetify.breakpoint.xs ? 'center' : 'space-around'"
        class="mr-0 pb-10"
      >
        <v-col cols="7" sm="2">
          <v-img
            :src="require('../assets/lion.png')"
            :height="$vuetify.breakpoint.xs ? '220' : '300'"
            width="200px"
          />
        </v-col>
        <v-divider class="hidden-xs-only" vertical></v-divider>

        <v-col
          cols="12"
          sm="3"
          :class="$vuetify.breakpoint.xs ? 'pt-0' : 'pt-10'"
        >
          <v-row
            :class="
              $vuetify.breakpoint.xs ? 'justify-center' : 'justify-start ml-5'
            "
          >
            <v-card flat width="250px" class="my-10 px-4 py-2">
              <h3>Gradinita Anastasya</h3>
            </v-card></v-row
          >
          <div
            class="d-flex align-center mb-3 ml-4"
            v-for="(info, i) in infoList"
            :key="'A' + i"
            cols="2"
          >
            <v-icon size="35" :color="info.color" class="ml-2 mr-3">{{
              info.icon
            }}</v-icon>
            <h4 class="white--text">{{ info.title }}</h4>
          </div>
        </v-col>
        <v-divider class="hidden-xs-only" vertical></v-divider>
        <v-col cols="12" sm="3" class="pt-10">
          <v-row class="justify-center">
            <v-card flat width="260px" class="my-10 px-4 py-2">
              <h3>Retele de socializare</h3>
            </v-card>
          </v-row>
          <v-btn
            small
            elevation="1"
            class="mr-3"
            fab
            color="white"
            v-for="(social, i) in socials"
            :key="'D' + i"
            target="_blank"
            :href="social.link"
          >
            <v-icon class="" :color="social.color">{{ social.icon }}</v-icon>
          </v-btn>
        </v-col>
        <v-divider class="hidden-xs-only" vertical></v-divider>

        <v-col cols="10" sm="4" class="pt-10">
          <v-row class="justify-center">
            <v-card flat width="300px" class="my-10 px-4 py-2">
              <h3>Locatie</h3>
            </v-card>
          </v-row>
          <v-row class="justify-center"
            ><div class="mapouter">
              <div class="gmap_canvas">
                <iframe
                  width="350px"
                  :class="$vuetify.breakpoint.xs ? 'ml-3' : 'ml-0'"
                  height="200"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=%C8%98oseaua%20Unirii%20327,%20Comuna%20Corbeanca,%20Romania&t=&z=15&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div></div
          ></v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>

    <v-card-text class="black--text my-4">
      <h3>
        © {{ new Date().getFullYear() }} - All Rights Reserved - Anastasya
        Academy
      </h3>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FooterComponent",

  data: () => ({
    infoList: [
      {
        icon: "mdi-phone",
        title: "0314.361.115",
        color: "white",
      },
      {
        icon: "mdi-email",
        title: "office@anastasya.ro",
        color: "white",
      },
      {
        icon: "mdi-map-marker",
        title: "Str. Sos Unirii, Nr 327, Corbeanca, Ilfov",
        color: "white",
      },
      {
        icon: "mdi-calendar",
        title: "Program: 08:00 - 19:00",
        color: "white",
      },
    ],
    socials: [
      {
        icon: "mdi-facebook",
        color: "indigo",
        link: "https://www.facebook.com/people/Anastasya-Gradinita-Scoala/100009468179608/",
      },
      {
        icon: "mdi-youtube",
        color: "red",
        link: "https://www.youtube.com/watch?v=H_FCg02XLXQ",
      },
    ],
  }),
};
</script>
<style scoped></style>
