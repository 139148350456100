<template>
  <v-container fluid grid-list-xs class="bgFood sectDist">
    <v-row class="justify-center text-center mb-10 mx-0" id="food">
      <v-col cols="12" sm="4">
        <div class="subSection">
          <v-chip label class="red"
            ><p class="mb-0 white--text">Cum arata meniul nostru?</p></v-chip
          >
        </div>
        <h1 class="mb-3">Alimentatie</h1>
      </v-col>
    </v-row>
    <v-row justify="space-around" class="pa-0 pt-5 ml-0">
      <v-col cols="9" sm="3" class="pa-0">
        <div
          class="my-5"
          :class="$vuetify.breakpoint.xs ? '' : 'd-inline-flex'"
        >
          <h2
            class="mb-5"
            :class="$vuetify.breakpoint.xs ? '' : 'verticalTextRight'"
          >
            Luni
          </h2>

          <div
            class="boxBlueOuter"
            :class="{
              boxMenuSizeSm: $vuetify.breakpoint.sm,
              boxMenuSizeMd: $vuetify.breakpoint.md,
            }"
          >
            <v-card
              elevation="0"
              :width="$vuetify.breakpoint.sm ? '300px' : '350px'"
              min-height="190px"
            >
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="4" md="3">
                  <v-chip small color="orange" class="white--text"
                    ><span class="hidden-sm-and-up">Mic-</span> Dejun</v-chip
                  >
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5 class="pt-1">Cereale asortate cu lapte</h5>
                </v-col>
              </v-row>
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="3" md="3">
                  <v-chip small color="red" class="white--text">Pranz</v-chip>
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5>
                    Ciorba de legume, Ostropel din piept de curcan cu piure de
                    cartofi, salata de varza
                  </h5></v-col
                >
              </v-row>
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="3" md="3">
                  <v-chip small color="purple" class="white--text"
                    >Gustari</v-chip
                  >
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5 class="pt-1">Mere, Chec</h5></v-col
                >
              </v-row>
            </v-card>
          </div>
        </div>
        <div
          class="mb-5 mt-10"
          :class="$vuetify.breakpoint.xs ? '' : 'd-inline-flex'"
        >
          <h2
            class="mb-5"
            :class="$vuetify.breakpoint.xs ? '' : 'verticalTextRight'"
          >
            Marti
          </h2>

          <div
            class="boxOrangeOuter"
            :class="{
              boxMenuSizeSm: $vuetify.breakpoint.sm,
              boxMenuSizeMd: $vuetify.breakpoint.md,
            }"
          >
            <v-card
              elevation="0"
              :width="$vuetify.breakpoint.sm ? '300px' : '350px'"
              min-height="190px"
            >
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="4" md="3">
                  <v-chip small color="orange" class="white--text"
                    ><span class="hidden-sm-and-up">Mic-</span> Dejun</v-chip
                  >
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5 class="pt-1">
                    Sandwich-uri cu gem, Ceai indulcit cu mere
                  </h5>
                </v-col>
              </v-row>
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="3" md="3">
                  <v-chip small color="red" class="white--text">Pranz</v-chip>
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5>
                    Supa-crema de broccoli cu crutoane, Varza a la Cluj cu
                    mamaliga
                  </h5></v-col
                >
              </v-row>
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="3" md="3">
                  <v-chip small color="purple" class="white--text"
                    >Gustari</v-chip
                  >
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5 class="pt-1">Legume asortate, Iaurt, Foccacia</h5></v-col
                >
              </v-row>
            </v-card>
          </div>
        </div>
      </v-col>
      <v-col
        cols="9"
        :sm="$vuetify.breakpoint.md ? '1' : '3'"
        :offset-sm="$vuetify.breakpoint.md ? '1' : '0'"
        class="pa-0 hidden-xs-only pr-6"
      >
        <vc-donut
          class="mt-4"
          :size="$vuetify.breakpoint.md ? 300 : 430"
          :sections="sections"
          :thickness="55"
          :total="100"
          >Exemplu meniu</vc-donut
        >
      </v-col>
      <v-col cols="9" sm="3" class="pa-0">
        <div
          class="my-5"
          :class="$vuetify.breakpoint.xs ? '' : 'd-inline-flex'"
        >
          <h2
            class="mb-5"
            :class="$vuetify.breakpoint.xs ? '' : 'verticalTextRight'"
          >
            Miercuri
          </h2>

          <div
            class="boxRedOuter"
            :class="{
              boxMenuSizeSm: $vuetify.breakpoint.sm,
              boxMenuSizeMd: $vuetify.breakpoint.md,
            }"
          >
            <v-card
              elevation="0"
              :width="$vuetify.breakpoint.sm ? '300px' : '350px'"
              min-height="190px"
            >
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="4" md="3">
                  <v-chip small color="orange" class="white--text"
                    ><span class="hidden-sm-and-up">Mic-</span> Dejun</v-chip
                  >
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5 class="pt-1 text-center">
                    Tartine cu cascaval, Ceai indulcit cu miere
                  </h5>
                </v-col>
              </v-row>
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="3" md="3">
                  <v-chip small color="red" class="white--text">Pranz</v-chip>
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5 class="text-center">
                    Supa de pui cu galusti, Sufleu de conopida cu carne de pui
                  </h5></v-col
                >
              </v-row>
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="3" md="3">
                  <v-chip small color="purple" class="white--text"
                    >Gustari</v-chip
                  >
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5 class="pt-1 text-center">
                    Mere, Biscuiti de casa
                  </h5></v-col
                >
              </v-row>
            </v-card>
          </div>
        </div>
        <div
          class="mb-5 mt-10"
          :class="$vuetify.breakpoint.xs ? '' : 'd-inline-flex'"
        >
          <h2
            class="mb-5"
            :class="$vuetify.breakpoint.xs ? '' : 'verticalTextRight'"
          >
            Joi
          </h2>
          <div
            class="boxLightPurpleOuter"
            :class="{
              boxMenuSizeSm: $vuetify.breakpoint.sm,
              boxMenuSizeMd: $vuetify.breakpoint.md,
            }"
          >
            <v-card
              elevation="0"
              :width="$vuetify.breakpoint.sm ? '300px' : '350px'"
              min-height="190px"
            >
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="4" md="3">
                  <v-chip small color="orange" class="white--text"
                    ><span class="hidden-sm-and-up">Mic-</span> Dejun</v-chip
                  >
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5 class="pt-1">
                    Pizza cu legume si cascaval, Ceai de fructe indulcit cu
                    miere
                  </h5>
                </v-col>
              </v-row>
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="3" md="3">
                  <v-chip small color="red" class="white--text">Pranz</v-chip>
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5>
                    Mamaliga cu branza si smantana, Chiftele di pste cu legume
                    gratinate
                  </h5></v-col
                >
              </v-row>
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="3" md="3">
                  <v-chip small color="purple" class="white--text"
                    >Gustari</v-chip
                  >
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5 class="pt-1">Kiwi, Saratele</h5></v-col
                >
              </v-row>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      :class="$vuetify.breakpoint.xs ? '' : 'ml-12 mt-10'"
    >
      <v-col cols="9" sm="3" class="pa-0">
        <div
          class="mb-5"
          :class="$vuetify.breakpoint.xs ? '' : 'd-inline-flex'"
        >
          <h2
            class="mb-5"
            :class="$vuetify.breakpoint.xs ? '' : 'verticalTextRight'"
          >
            Vineri
          </h2>

          <div
            class="boxPurpleOuter"
            :class="{
              boxMenuSizeSm: $vuetify.breakpoint.sm,
              boxMenuSizeMd: $vuetify.breakpoint.md,
            }"
          >
            <v-card
              elevation="0"
              :width="$vuetify.breakpoint.sm ? '300px' : '350px'"
              min-height="190px"
            >
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="4" md="3">
                  <v-chip small color="orange" class="white--text"
                    ><span class="hidden-sm-and-up">Mic-</span> Dejun</v-chip
                  >
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5 class="pt-1">Cereale asortate cu lapte</h5>
                </v-col>
              </v-row>
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="3" md="3">
                  <v-chip small color="red" class="white--text">Pranz</v-chip>
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5>
                    Ciorba de pui cu taitei, <br />Tocanita greceasca cu branza
                    telemea
                  </h5></v-col
                >
              </v-row>
              <v-row justify="center" class="ma-0 pa-0">
                <v-col cols="3" md="3">
                  <v-chip small color="purple" class="white--text"
                    >Gustari</v-chip
                  >
                </v-col>
                <v-col cols="10" md="9" class="text-center">
                  <h5 class="pt-1">Mere, Banana</h5></v-col
                >
              </v-row>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FoodComponent",

  data() {
    return {
      sections: [
        { label: "Luni", value: 20, color: "#f95f44" },
        { label: "Marti", value: 20, color: "#849cfc" },
        { label: "Miercuri", value: 20, color: "#6d1bff" },
        { label: "Joi", value: 20, color: "orange" },
        { label: "Vineri", value: 20, color: "#7bd7f2" },
      ],
    };
  },
};
</script>
<style scoped>
.bgFood {
  background-image: url("../assets/slides/Slide4.jpg");
  width: 100%;
  min-height: 120vh;
  background-size: cover;
}
.boxMenuSizeSm {
  width: 300px;
}
.boxMenuSizeMd {
  width: 300px;
}
.verticalText {
  writing-mode: vertical-rl;
  /*text-orientation: sideways;*/
  transform: scale(-1);
}
.verticalTextRight {
  writing-mode: vertical-rl;
  /*text-orientation: sideways;*/
  transform: scale(-1);
}
</style>
