var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"bgFood sectDist",attrs:{"fluid":"","grid-list-xs":""}},[_c('v-row',{staticClass:"justify-center text-center mb-10 mx-0",attrs:{"id":"food"}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"subSection"},[_c('v-chip',{staticClass:"red",attrs:{"label":""}},[_c('p',{staticClass:"mb-0 white--text"},[_vm._v("Cum arata meniul nostru?")])])],1),_c('h1',{staticClass:"mb-3"},[_vm._v("Alimentatie")])])],1),_c('v-row',{staticClass:"pa-0 pt-5 ml-0",attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"9","sm":"3"}},[_c('div',{staticClass:"my-5",class:_vm.$vuetify.breakpoint.xs ? '' : 'd-inline-flex'},[_c('h2',{staticClass:"mb-5",class:_vm.$vuetify.breakpoint.xs ? '' : 'verticalTextRight'},[_vm._v(" Luni ")]),_c('div',{staticClass:"boxBlueOuter",class:{
            boxMenuSizeSm: _vm.$vuetify.breakpoint.sm,
            boxMenuSizeMd: _vm.$vuetify.breakpoint.md,
          }},[_c('v-card',{attrs:{"elevation":"0","width":_vm.$vuetify.breakpoint.sm ? '300px' : '350px',"min-height":"190px"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"orange"}},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v("Mic-")]),_vm._v(" Dejun")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',{staticClass:"pt-1"},[_vm._v("Cereale asortate cu lapte")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"red"}},[_vm._v("Pranz")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',[_vm._v(" Ciorba de legume, Ostropel din piept de curcan cu piure de cartofi, salata de varza ")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"purple"}},[_vm._v("Gustari")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',{staticClass:"pt-1"},[_vm._v("Mere, Chec")])])],1)],1)],1)]),_c('div',{staticClass:"mb-5 mt-10",class:_vm.$vuetify.breakpoint.xs ? '' : 'd-inline-flex'},[_c('h2',{staticClass:"mb-5",class:_vm.$vuetify.breakpoint.xs ? '' : 'verticalTextRight'},[_vm._v(" Marti ")]),_c('div',{staticClass:"boxOrangeOuter",class:{
            boxMenuSizeSm: _vm.$vuetify.breakpoint.sm,
            boxMenuSizeMd: _vm.$vuetify.breakpoint.md,
          }},[_c('v-card',{attrs:{"elevation":"0","width":_vm.$vuetify.breakpoint.sm ? '300px' : '350px',"min-height":"190px"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"orange"}},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v("Mic-")]),_vm._v(" Dejun")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',{staticClass:"pt-1"},[_vm._v(" Sandwich-uri cu gem, Ceai indulcit cu mere ")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"red"}},[_vm._v("Pranz")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',[_vm._v(" Supa-crema de broccoli cu crutoane, Varza a la Cluj cu mamaliga ")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"purple"}},[_vm._v("Gustari")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',{staticClass:"pt-1"},[_vm._v("Legume asortate, Iaurt, Foccacia")])])],1)],1)],1)])]),_c('v-col',{staticClass:"pa-0 hidden-xs-only pr-6",attrs:{"cols":"9","sm":_vm.$vuetify.breakpoint.md ? '1' : '3',"offset-sm":_vm.$vuetify.breakpoint.md ? '1' : '0'}},[_c('vc-donut',{staticClass:"mt-4",attrs:{"size":_vm.$vuetify.breakpoint.md ? 300 : 430,"sections":_vm.sections,"thickness":55,"total":100}},[_vm._v("Exemplu meniu")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"9","sm":"3"}},[_c('div',{staticClass:"my-5",class:_vm.$vuetify.breakpoint.xs ? '' : 'd-inline-flex'},[_c('h2',{staticClass:"mb-5",class:_vm.$vuetify.breakpoint.xs ? '' : 'verticalTextRight'},[_vm._v(" Miercuri ")]),_c('div',{staticClass:"boxRedOuter",class:{
            boxMenuSizeSm: _vm.$vuetify.breakpoint.sm,
            boxMenuSizeMd: _vm.$vuetify.breakpoint.md,
          }},[_c('v-card',{attrs:{"elevation":"0","width":_vm.$vuetify.breakpoint.sm ? '300px' : '350px',"min-height":"190px"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"orange"}},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v("Mic-")]),_vm._v(" Dejun")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',{staticClass:"pt-1 text-center"},[_vm._v(" Tartine cu cascaval, Ceai indulcit cu miere ")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"red"}},[_vm._v("Pranz")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',{staticClass:"text-center"},[_vm._v(" Supa de pui cu galusti, Sufleu de conopida cu carne de pui ")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"purple"}},[_vm._v("Gustari")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',{staticClass:"pt-1 text-center"},[_vm._v(" Mere, Biscuiti de casa ")])])],1)],1)],1)]),_c('div',{staticClass:"mb-5 mt-10",class:_vm.$vuetify.breakpoint.xs ? '' : 'd-inline-flex'},[_c('h2',{staticClass:"mb-5",class:_vm.$vuetify.breakpoint.xs ? '' : 'verticalTextRight'},[_vm._v(" Joi ")]),_c('div',{staticClass:"boxLightPurpleOuter",class:{
            boxMenuSizeSm: _vm.$vuetify.breakpoint.sm,
            boxMenuSizeMd: _vm.$vuetify.breakpoint.md,
          }},[_c('v-card',{attrs:{"elevation":"0","width":_vm.$vuetify.breakpoint.sm ? '300px' : '350px',"min-height":"190px"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"orange"}},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v("Mic-")]),_vm._v(" Dejun")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',{staticClass:"pt-1"},[_vm._v(" Pizza cu legume si cascaval, Ceai de fructe indulcit cu miere ")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"red"}},[_vm._v("Pranz")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',[_vm._v(" Mamaliga cu branza si smantana, Chiftele di pste cu legume gratinate ")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"purple"}},[_vm._v("Gustari")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',{staticClass:"pt-1"},[_vm._v("Kiwi, Saratele")])])],1)],1)],1)])])],1),_c('v-row',{class:_vm.$vuetify.breakpoint.xs ? '' : 'ml-12 mt-10',attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"9","sm":"3"}},[_c('div',{staticClass:"mb-5",class:_vm.$vuetify.breakpoint.xs ? '' : 'd-inline-flex'},[_c('h2',{staticClass:"mb-5",class:_vm.$vuetify.breakpoint.xs ? '' : 'verticalTextRight'},[_vm._v(" Vineri ")]),_c('div',{staticClass:"boxPurpleOuter",class:{
            boxMenuSizeSm: _vm.$vuetify.breakpoint.sm,
            boxMenuSizeMd: _vm.$vuetify.breakpoint.md,
          }},[_c('v-card',{attrs:{"elevation":"0","width":_vm.$vuetify.breakpoint.sm ? '300px' : '350px',"min-height":"190px"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"orange"}},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v("Mic-")]),_vm._v(" Dejun")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',{staticClass:"pt-1"},[_vm._v("Cereale asortate cu lapte")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"red"}},[_vm._v("Pranz")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',[_vm._v(" Ciorba de pui cu taitei, "),_c('br'),_vm._v("Tocanita greceasca cu branza telemea ")])])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"purple"}},[_vm._v("Gustari")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"10","md":"9"}},[_c('h5',{staticClass:"pt-1"},[_vm._v("Mere, Banana")])])],1)],1)],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }