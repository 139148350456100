import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyAJt0OLisvVgi7PXu_USPG5zPm6l-rc_2o",
  authDomain: "anastasya-51351.firebaseapp.com",
  projectId: "anastasya-51351",
  storageBucket: "anastasya-51351.appspot.com",
  messagingSenderId: "175062847518",
  appId: "1:175062847518:web:49ac8d5ff19f1ea2859e0f",
  measurementId: "G-TL47SF4VLM",
};
var secondaryAppConfig = {
  apiKey: "AIzaSyBE364tAUNjS5a85O-90Op0CwWamDE0Pf8",
  authDomain: "eduprivate2020.firebaseapp.com",
  databaseURL: "https://eduprivate2020.firebaseio.com",
  projectId: "eduprivate2020",
  storageBucket: "eduprivate2020.appspot.com",
  messagingSenderId: "18880745761",
  appId: "1:18880745761:web:1967de915b4c979daf6e60",
  measurementId: "G-79GXBVR3P7",
};

const fb = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
Vue.prototype.$analytics = fb.analytics();

var secondary = firebase.initializeApp(secondaryAppConfig, "Secondary");
const app = secondary.firestore();

export { fb, db, app };
