<template>
  <v-container fluid grid-list-xs class="sectDist">
    <v-row justify="center" class="mx-0 mb-10" id="funds">
      <h1>Fonduri</h1>
    </v-row>
    <v-row justify="center" class="mx-0 hidden-xs-only">
      <v-col cols="10" sm="3">
        <v-row
          justify="center"
          class="py-8 mx-0"
          v-for="(item, i) in leftList"
          :key="i"
        >
          <v-icon class="text-center" :color="item.color" size="50"
            >mdi-calendar</v-icon
          >
          <h2 class="mt-2 ml-5">In anul {{ item.date }},</h2>
          <v-card class="my-4 px-4 pt-4" max-width="300px">
            <p>
              {{ item.description }}
            </p>
          </v-card>
        </v-row>
      </v-col>
      <v-col cols="10" sm="4">
        <v-img :src="require('../assets/working.jpeg')" height="80%" />
      </v-col>
      <v-col cols="10" sm="3">
        <v-row
          class="justify-center py-8 mx-0"
          v-for="(item, i) in rightList"
          :key="i"
        >
          <h2 class="mt-2 mr-5">In anul {{ item.date }},</h2>
          <v-icon class="text-center" :color="item.color" size="50"
            >mdi-calendar</v-icon
          >

          <v-card class="my-4 px-4 pt-4" max-width="300px">
            <p>
              {{ item.description }}
            </p>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="mx-0 hidden-sm-and-up">
      <v-col cols="10">
        <v-row
          justify="center"
          class="py-8 mx-0"
          v-for="(item, i) in xsCenterList"
          :key="i"
        >
          <v-icon class="text-center" :color="item.color" size="50"
            >mdi-calendar</v-icon
          >
          <h2 class="mt-2 ml-5">In anul {{ item.date }},</h2>
          <v-card class="my-4 px-4 pt-4" max-width="300px">
            <p>
              {{ item.description }}
            </p>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FundsView",
  data() {
    return {
      leftList: [
        {
          date: "2023",
          description:
            "de finantare in baza costului standard per elev in valoare de 731000 Ron din care 403382 salarii, 257618 taxe, 70000 bunuri si servicii.",
          color: "purple",
        },
        {
          date: "2021",
          description:
            "de finantare in baza costului standard per elev in valoare de 580000 Ron din care 309888 salarii, 225112 taxe, 45000 bunuri si servicii.",
          color: "orange",
        },

        {
          date: "2019",
          description:
            "de finanțare in baza costului standard per elev in valoare de 417000 RON, din care 226858 salarii, 165142 taxe, 25000 bunuri si servicii.",
          color: "blue",
        },
        {
          date: "2017",
          description:
            "de finantare in baza costului standard per elev in valoare de 176000 Ron din care 163000 taxe salariale si 13000 bunuri si servicii.",
          color: "yellow",
        },
      ],

      rightList: [
        {
          date: "2022",
          description:
            "de finantare in baza costului standard per elev in valoare de 816000 Ron din care 423364 salarii, 323636 taxe, 69000 bunuri si servicii.",
          color: "purple",
        },
        {
          date: "2020",
          description:
            "de finantare in baza costului standard per elev in valoare de 720000 Ron din care 400450 salarii, 273550 taxe, 46000 bunuri si servicii.",
          color: "red",
        },
        {
          date: "2018",
          description:
            "de finantare in baza costului standard per elev in valoare de 210000 Ron din care 113107 salarii, 80893 taxe, 16000 bunuri si servicii.",
          color: "pink",
        },
      ],
      xsCenterList: [
        {
          date: "2023",
          description:
            "de finantare in baza costului standard per elev in valoare de 731000 Ron din care 403382 salarii, 257618 taxe, 70000 bunuri si servicii.",
          color: "purple",
        },
        {
          date: "2022",
          description:
            "de finantare in baza costului standard per elev in valoare de 816000 Ron din care 423364 salarii, 323636 taxe, 69000 bunuri si servicii.",
          color: "orange",
        },
        {
          date: "2021",
          description:
            "de finantare in baza costului standard per elev in valoare de 580000 Ron din care 309888 salarii, 225112 taxe, 45000 bunuri si servicii.",
          color: "yellow",
        },
        {
          date: "2020",
          description:
            "de finantare in baza costului standard per elev in valoare de 720000 Ron din care 400450 salarii, 273550 taxe, 46000 bunuri si servicii.",
          color: "red",
        },
        {
          date: "2019",
          description:
            "de finanțare in baza costului standard per elev in valoare de 417000 RON, din care 226858 salarii, 165142 taxe, 25000 bunuri si servicii.",
          color: "blue",
        },
        {
          date: "2018",
          description:
            "de finantare in baza costului standard per elev in valoare de 210000 Ron din care 113107 salarii, 80893 taxe, 16000 bunuri si servicii.",
          color: "pink",
        },

        {
          date: "2017",
          description:
            "de finantare in baza costului standard per elev in valoare de 176000 Ron din care 163000 taxe salariale si 13000 bunuri si servicii.",
          color: "yellow",
        },
      ],
    };
  },
};
</script>
<style></style>
