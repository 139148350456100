<template>
  <v-app>
    <TopNav class="hidden-xs-only" />

    <v-app-bar
      app
      color="white"
      class="mx-0 px-0 hidden-xs-only margTop"
      dark
      :class="{ margTop: scrollPosition < 10, margNone: scrollPosition > 50 }"
    >
      <v-toolbar-items>
        <v-btn text class="mx-0 px-0" @click.prevent="goHome('home')">
          <v-img
            :src="require('././assets/logo.png')"
            cover
            class="my-3 mx-0 px-0"
            width="140px"
          />
        </v-btn>

        <v-btn
          text
          color="purple"
          v-for="(link, i) in links"
          :key="'C' + i"
          @click.prevent="goHome(link.to)"
        >
          <v-icon :color="link.color" :class="link.title ? 'mr-2' : ''">{{
            link.icon
          }}</v-icon>
          <h5 class="black--text">{{ link.title }}</h5>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" class="px-3" @click="show = !show">
              <h5 class="black--text">Ajutor</h5>
              <v-icon color="black">{{
                show ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </template>

          <v-list style="min-width: 145px">
            <v-subheader>Fonduri</v-subheader>
            <v-divider class="my-1"></v-divider>
            <v-list-item>
              <v-btn text color="white" class="routerBtn" to="/transparenta">
                <v-icon color="red" left
                  >mdi-account-arrow-right-outline</v-icon
                >
                <h5 class="black--text">Transparenta</h5></v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          @click.prevent="goHome('contact')"
          color="purple"
          class="boxPurple"
          v-if="scroll"
        >
          Aplica acum
        </v-btn>
        <v-col
          cols="12"
          sm="2"
          class="d-inline-flex mr-0 pr-0 hidden-xs-only"
          v-if="scroll"
        >
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            nudge-bottom="70"
            nudge-left="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                elevation="1"
                class="mr-3"
                fab
                color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="black--text">mdi-phone</v-icon>
              </v-btn>
            </template>

            <v-card width="400px" height="325px">
              <v-list>
                <v-row justify="center">
                  <v-col
                    cols="10"
                    :md="header.col"
                    v-for="(header, i) in contactList"
                    :key="i"
                    class="pa-0 ma-0"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          header.position
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          header.title
                        }}</v-list-item-subtitle>
                        <div
                          v-for="(item, e) in header.items"
                          :key="e"
                          class="d-inline-flex"
                        >
                          <h3 class="text-center">{{ item }} &nbsp;</h3>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-list>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn class="mt-2 mr-4" @click="menu2 = false">
                  Inchide
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-btn
            small
            elevation="1"
            class="mr-3 hidden-xs-only"
            fab
            color="white"
            v-for="(social, i) in socials"
            :key="'D' + i"
            target="_blank"
            :href="social.link"
          >
            <v-icon class="" :color="social.color">{{ social.icon }}</v-icon>
          </v-btn>
        </v-col>
      </v-toolbar-items>
    </v-app-bar>
    <v-app-bar class="hidden-sm-and-up" app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-btn text to="/home" class="pl-0">
        <v-img
          :src="require('././assets/logo.png')"
          contain
          class="my-3"
          width="160px"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <v-menu
        v-model="menu3"
        :close-on-content-click="false"
        nudge-bottom="70"
        nudge-left="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            elevation="1"
            class="mr-3"
            fab
            color="white"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="black--text">mdi-phone</v-icon>
          </v-btn>
        </template>

        <v-card width="400px" height="100%">
          <v-list>
            <v-row justify="center">
              <v-col
                cols="10"
                :md="header.col"
                v-for="(header, i) in contactList"
                :key="i"
                class="pa-0 ma-0"
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ header.position }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      header.title
                    }}</v-list-item-subtitle>
                    <div
                      v-for="(item, e) in header.items"
                      :key="e"
                      class="d-inline-flex"
                    >
                      <h3 class="text-center">{{ item }} &nbsp;</h3>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn class="mt-2 mr-4" @click="menu3 = false"> Inchide </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn
        small
        elevation="1"
        class="mr-3"
        fab
        color="white"
        v-for="(social, i) in socials"
        :key="'D' + i"
        target="_blank"
        :href="social.link"
      >
        <v-icon class="" :color="social.color">{{ social.icon }}</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      left
      temporary
      class="hidden-sm-and-up"
    >
      <v-list-item v-for="(item, i) in links" :key="i">
        <v-list-item-icon @click.prevent="goHome(item.to)">
          <v-icon v-html="item.icon" :color="item.color"></v-icon>
        </v-list-item-icon>

        <v-list-item-title
          v-html="item.title"
          @click.prevent="goHome(item.to)"
        ></v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-group :value="false" active-class="black--text">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-user</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="black--text">Ajutor</v-list-item-title>
        </template>

        <v-list-item-content class="py-0">
          <span class="d-inline-flex mx-5" @click.prevent="goHelp('funds')">
            <v-list-item-icon>
              <v-icon color="black">mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="black--text"
              >Transparenta</v-list-item-title
            >
          </span>
        </v-list-item-content>
      </v-list-group>
    </v-navigation-drawer>

    <v-main class="pt-15">
      <router-view />
    </v-main>
    <v-footer class="pa-0 ma-0">
      <Footer />
    </v-footer>
  </v-app>
</template>
<script>
import Footer from "./components/Footer.vue";
import TopNav from "./components/nav/TopNav.vue";

import { mdiImageMultiple } from "@mdi/js";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    TopNav,
    Footer,
  },
  data: () => ({
    scrollPosition: null,
    drawer: false,
    group: null,
    show3: false,
    show: false,
    scroll: false,
    menu: false,
    menu2: false,
    menu3: false,
    message: false,
    hints: true,
    contactList: [
      { title: "Director", items: ["0724.21.58.32 "], col: "5", img: true },
      { title: "Administrator", items: ["0766.42.55.77"], col: "5" },
      {
        title: "Secretariat",
        items: ["0314. 361.115  /", " 0771.316.372  /", "0212.666.066 "],
        col: "10",
      },
      {
        title: "Email",
        items: ["office@anastasya.ro /", "flori_meila@yahoo.com"],
        col: "10",
      },
    ],

    links: [
      {
        icon: "mdi-home",
        to: "home",
        color: "red",
      },
      {
        icon: "mdi-notebook",
        title: "Educatie",
        to: "school",
        color: "purple",
      },
      {
        icon: "mdi-food-apple",
        title: "Alimentatie",
        to: "food",
        color: "lightPurple",
      },

      // {
      //   icon: "mdi-account-multiple",
      //   title: "Profesori",
      //   to: "teachers",
      //   color: "blue",
      // },
      {
        icon: mdiImageMultiple,
        title: "Galerie",
        to: "gallery",
        color: "orange",
      },
      {
        icon: "mdi-calendar",
        title: "Inscrieri",
        to: "entries",
        color: "yellow",
      },
      {
        icon: mdiImageMultiple,
        title: "Contact",
        to: "contact",
        color: "pink",
      },
    ],
    socials: [
      {
        icon: "mdi-facebook",
        color: "indigo",
        link: "https://www.facebook.com/people/Anastasya-Gradinita-Scoala/100009468179608/",
      },
      {
        icon: "mdi-youtube",
        color: "red",
        link: "https://www.youtube.com/watch?v=H_FCg02XLXQ",
      },
    ],
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },

  mounted() {
    this.GET_CURRENT_PROFILE();
    this.GET_ALBUMS();
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    ...mapActions(["GET_ALBUMS", "GET_CURRENT_PROFILE"]),

    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition < 1) {
        this.scroll = false;
      } else if (this.scrollPosition > 1) {
        this.scroll = true;
      }
    },
    goHome(name) {
      if (this.$route.path === "/transparenta") {
        this.$router.push("/").then(() => {
          this.$vuetify.goTo("#" + name, { offset: 0 });
        });
      } else {
        this.$vuetify.goTo("#" + name, { offset: 0 });
      }
      if (this.drawer === true) {
        this.drawer = false;
      }
    },
    goHelp(name) {
      if (this.$route.path !== "/transparenta") {
        this.$router.push("/transparenta").then(() => {
          this.$vuetify.goTo("#" + name, { offset: 0 });
        });
      } else {
        this.$vuetify.goTo("#" + name, { offset: 0 });
      }
      if (this.drawer === true) {
        this.drawer = false;
      }
    },
  },
};
</script>
<style>
* {
  font-family: "Quicksand", sans-serif;
  font-weight: 600 !important;
}
h1 {
  font-size: 50px;
  font-weight: 60;
}

.subSection p {
  font-family: "Schoolbell", cursive;
}

.btnPad {
  padding: 0px 15px !important;
}
.topBar {
  padding: 15px 0px;
}
.sectDist {
  padding: 100px 0px !important;
}
.sectDist1 {
  padding: 60px 0px 100px 0px !important;
}
p {
  font-family: "Prompt", sans-serif;
  font-size: 19px;
}
.topInfo {
  height: 20px;
}
.routerBtn {
  background: white;
}
.margTop {
  margin-top: 65px !important;
}
.margNone {
  margin-top: 0px !important;
}
.router-link-menu span {
  color: black;
}
.btnRound {
  border-radius: 2em;
}
.boxRed {
  box-shadow: rgba(240, 3, 3, 0.26) 0px 1px 20px 1px;
}
.boxBlue {
  box-shadow: rgb(123, 215, 242, 0.46) 0px 1px 20px 1px;
}
.boxOrange {
  box-shadow: rgba(255, 127, 0, 0.4) 0px 1px 20px 1px;
}
.boxGreen {
  box-shadow: rgb(166, 236, 138, 0.46) 0px 1px 20px 1px;
}
.boxLightPurple {
  box-shadow: rgb(132, 156, 252, 0.46) 0px 1px 20px 1px;
}
.boxYellow {
  box-shadow: rgb(255, 217, 98, 0.46) 0px 1px 20px 1px;
}
.boxPurple {
  box-shadow: rgb(109, 27, 250, 0.3) 0px 1px 20px 1px;
}
.boxRedOuter {
  box-shadow: rgba(240, 3, 3, 0.25) 0px 1px 30px 1px;
}
.boxBlueOuter {
  box-shadow: rgb(123, 215, 242, 0.4) 0px 1px 30px 1px;
}
.boxOrangeOuter {
  box-shadow: rgb(249, 95, 68, 0.2) 0px 1px 30px 1px;
}
.boxGreenOuter {
  box-shadow: rgb(166, 236, 138, 0.6) 0px 1px 30px 1px;
}
.boxLightPurpleOuter {
  box-shadow: rgb(132, 156, 252, 0.35) 0px 1px 30px 1px;
}
.boxYellowOuter {
  box-shadow: rgb(255, 217, 98, 0.66) 0px 1px 30px 1px;
}
.boxPurpleOuter {
  box-shadow: rgb(109, 27, 250, 0.26) 0px 1px 20px 1px;
}
.disable-events {
  pointer-events: none;
}
</style>
